// Module Imports
import React from "react"
// Custom Imports
import Layout from "../components/layout"
import Hero from "../modules/hero"
import TheClub from "../modules/the-club"
import Executives from "../modules/executives"
import Contact from "../modules/contact"
import Partners from "../modules/partners"
// Data Imports
import { dataTheClub, dataExecutives, dataContact, dataPartners } from "../data"

const IndexPage = () => (
  <Layout
    title="Startseite"
    description="Förderverein der Meister- und Techniker aus- und -fortbildung im saarländischen Handwerk e.V."
  >
    <Hero
      headline={
        <>
          Wir machen Meister.
          <br />
          Wir machen Techniker.
        </>
      }
    />
    <TheClub id="club" {...dataTheClub} />
    <Executives
      id="executives"
      hasGreyBG
      headline="Der Vorstand"
      executives={dataExecutives}
    />
    <Contact id="contact" headline="Kontakt &amp; Downloads" {...dataContact} />
    <Partners id="partners" hasGreyBG partners={dataPartners} />
  </Layout>
)

export default IndexPage
