// Module Imports
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import { colors, spacing } from "../designsystem"
import { Headline1 } from "../elements"

// Component
const Hero = ({ className, headline }) => (
  <header className={className}>
    <StaticImage className="header__bg" src="../images/hero-bg.jpg" alt="" />
    <Headline1>{headline}</Headline1>
  </header>
)

// PropTypes
Hero.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
}

// Export default as styled component
export default styled(Hero)`
  position: relative;
  .header__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  h1 {
    text-align: center;
    color: ${colors.defaults.white};
    padding: ${spacing[5]} ${spacing[2]};
  }

  @media screen and (min-width: 400px) {
    h1 {
      padding: ${spacing[6]} ${spacing[2]};
    }
  }
`
