// Module Imports
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import { Section, Container } from "../layout"
import { Headline3, Text } from "../elements"
import { spacing, colors } from "../designsystem"
import CheckmarkSVG from "../images/checkmark.svg"

// Component
const TheClub = ({ className, ...props }) => (
  <Section className={className} {...props}>
    <Container>
      <Headline3 className="color-primary-semidark" as="h1">
        {props.headline}
      </Headline3>
      <Text dangerouslySetInnerHTML={{ __html: props.introTextHTML }} />
      <ul className="clean">
        {props.servicesList.map((listItemText, listItemKey) => (
          <li key={listItemKey}>{listItemText}</li>
        ))}
      </ul>
    </Container>
  </Section>
)

// PropTypes
TheClub.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  introTextHTML: PropTypes.string,
  servicesList: PropTypes.arrayOf(PropTypes.string),
}

// Export default as styled component
export default styled(TheClub)`
  ${Headline3} {
    margin-bottom: ${spacing[2]};
  }
  ${Text} {
    margin-bottom: ${spacing[3]};
    max-width: 50rem;
    color: ${colors.grey.semidark};
    line-height: 1.5;
  }

  li {
    position: relative;
    padding-left: 2.25rem;
    line-height: 1.5;
    &:before {
      content: url(${CheckmarkSVG});
      position: absolute;
      top: 0.25rem;
      left: 0;
      width: 24px;
      height: 24px;
    }
    &:not(:last-child) {
      margin-bottom: ${spacing[2]};
    }
  }

  @media screen and (min-width: 850px) {
    ${Text} {
      font-size: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 45%;
      font-size: 18px;
    }
  }
`
