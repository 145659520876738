// Module Imports
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ResonsiveEmbed from "react-responsive-embed"
// Custom Imports
import { Section, Container } from "../layout"
import { Headline3, Headline4, Bold } from "../elements"
import { spacing, colors, defaultsCSS } from "../designsystem"
import DownloadIcon from "../images/download.svg"

// Component
const Contact = ({ className, ...props }) => (
  <Section className={className} {...props}>
    <Container>
      <Headline3 as="h2" className="color-primary-semidark">
        {props.headline}
      </Headline3>
      <div className="contact-content">
        <ResonsiveEmbed
          title="Hier finden Sie uns!"
          src={props.mapEmbedPath}
          frameBorder="0"
          className="map-embed"
        />

        <article>
          <Headline4 as="h3">{props.title}</Headline4>
          <address>
            {props.street}
            <br />
            {props.zipAndCity}
          </address>
          <p>
            Tel.: {props.phone}
            <br />
            E-Mail: <a href={`mailto:${props.email}`}>{props.email}</a>
          </p>
          <Bold as="h4">Downloads</Bold>
          <ul className="clean">
            {props.downloads.map((download, key) => (
              <li key={key}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={download.filePath}
                >
                  {download.title}
                </a>
              </li>
            ))}
          </ul>
        </article>
      </div>
    </Container>
  </Section>
)

// PropTypes
Contact.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  mapEmbedPath: PropTypes.string,
  title: PropTypes.string,
  street: PropTypes.string,
  zipAndCity: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  downloads: PropTypes.arrayOf(PropTypes.object),
}

// Export default as styled component
export default styled(Contact)`
  ${Headline3} {
    margin-bottom: ${spacing[3]};
  }

  .contact-content {
    ${defaultsCSS.elevation[3]};

    ${Headline4}, ${Bold} {
      color: ${colors.primary.dark};
    }

    ${Headline4} {
      margin-bottom: ${spacing[3]};
      line-height: 1.4;
    }

    address,
    p {
      line-height: 1.5 !important;
      font-style: normal;
    }

    article {
      padding: ${spacing[2]};
      padding-bottom: ${spacing[3]};
    }

    p {
      margin-top: ${spacing[1]};
      margin-bottom: ${spacing[2]};
    }

    ul {
      flex-wrap: wrap;
      align-items: flex-start;
    }

    li {
      &:not(:last-child) {
        margin-bottom: ${spacing[2]};
      }
      a {
        position: relative;
        padding-left: 1.75rem;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: url(${DownloadIcon});
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  h4 {
    margin-top: ${spacing[4]};
    margin-bottom: ${spacing[2]};
  }

  @media screen and (min-width: 750px) {
    ul {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: 900px) {
    .contact-content {
      border-top: 4px solid ${colors.primary.semilight};
      display: grid;
      grid-template-columns: 1.5fr 1fr;
    }

    ul {
      justify-content: flex-start;
    }

    li {
      flex-basis: 175px;
    }

    article {
      max-width: 25rem;
      justify-self: center;
    }

    .contact-content div:has(.map-embed) {
      height: 100% !important;
    }
  }
`
