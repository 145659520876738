// Module Imports
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import { Section, Container } from "../layout"
import { Headline3 } from "../elements"
import { spacing, colors } from "../designsystem"

// Component
const Partners = ({ className, partners, ...props }) => (
  <Section className={className} {...props}>
    <Container>
      <Headline3 as="h2" className="color-primary-semidark">
        Unsere Partner
      </Headline3>
      <ul className="clean">
        {partners.map((partner, key) => (
          <li key={key}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={partner.link}
              title={partner.title}
            >
              {partner.Image}
            </a>
          </li>
        ))}
      </ul>
    </Container>
  </Section>
)

// PropTypes
Partners.propTypes = {
  className: PropTypes.string,
  partners: PropTypes.arrayOf(PropTypes.object),
}

// Export default as styled component
export default styled(Partners)`
  ${Headline3} {
    margin-bottom: ${spacing[3]};
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${spacing[1]};
    overflow: hidden;
    a {
      background: ${colors.grey.semilight};
      height: 150px;
      filter: grayscale(1);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 200ms ease-in-out;
      .parnter__logo {
        transition: inherit;
        width: 50px;
        height: auto;
      }

      &:hover {
        filter: grayscale(0);
        .parnter__logo {
          transform: scale(1.25);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    ul {
      grid-template-columns: repeat(4, 1fr);
      a .parnter__logo {
        width: 75px;
      }
    }
  }
`
