// Module Imports
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import Card from "../components/card"
import { Section, Container } from "../layout"
import { Headline3 } from "../elements"
import { spacing } from "../designsystem"

// Component
const Executives = ({ className, headline, executives, ...props }) => (
  <Section className={className} {...props}>
    <Container>
      <Headline3 className="color-primary-semidark" as="h3">
        {headline}
      </Headline3>
      <div className="executives-list">
        {executives.map((executive, executiveKey) => (
          <Card key={executiveKey} {...executive} />
        ))}
      </div>
    </Container>
  </Section>
)

// PropTypes
Executives.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  executives: PropTypes.array,
}

// Export default as styled component
export default styled(Executives)`
  ${Headline3} {
    margin-bottom: ${spacing[3]};
  }

  .executives-list {
    display: grid;
    grid-gap: ${spacing[3]};
  }

  @media screen and (min-width: 820px) {
    .executives-list {
      grid-template-columns: 1fr 1fr;
    }
  }
`
