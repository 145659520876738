// Module Imports
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// Custom Imports
import { Headline4, Bold, Text } from "../elements"
import { spacing, colors, defaultsCSS } from "../designsystem"
import OpenIcon from "../images/open.svg"

// Component
const Card = ({ className, Image, ...props }) => (
  <div className={className}>
    {Image}
    <article>
      <Headline4 className="color-primary-semidark" as="h1">
        {props.name}
      </Headline4>
      <Bold>{props.position}</Bold>
      <Text>
        {props.phone && (
          <>
            {props.phone}
            <br />
          </>
        )}
        <a className="email" href={`mailto:${props.email}`}>
          Email schreiben
          <img src={OpenIcon} alt="E-Mail schreiben" />
        </a>
      </Text>
    </article>
  </div>
)

// PropTypes
Card.propTypes = {
  className: PropTypes.string,
  Image: PropTypes.element,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

// Export default as styled component
export default styled(Card)`
  padding: ${spacing[2]};
  ${defaultsCSS.elevation[1]};
  max-width: 526px;
  text-align: center;

  .executive__image {
    width: 110px;
    height: 110px;
    margin-right: ${spacing[2]};
    overflow: hidden;
    img {
      border-radius: 50%;
    }
  }

  ${Headline4} {
    margin-bottom: 0.25rem;
  }

  ${Bold} {
    color: ${colors.grey.semidark};
    font-weight: 500;
  }

  ${Text} {
    margin-top: ${spacing[3]};
  }

  .email {
    color: ${colors.primary.semidark};
    &:hover {
      text-decoration: underline;
    }
  }

  a img {
    width: 1em;
    height: 1em;
    margin-left: ${spacing[0]};
    transform: translateY(2px);
  }

  // Media queries
  @media screen and (min-width: 414px) {
    display: flex;
    align-items: center;
    padding: ${spacing[3]};
    text-align: left;
    img.portrait {
      margin: 0 ${spacing[2]} 0 0;
    }
  }
`
